import api from "./config/apiConfig"
import { UserVideoForFeedback } from "../models/UserVideoForFeedback";

export const getVideosForFeedback = async (accessToken: string): Promise<
  UserVideoForFeedback[]
> => {
  try {
    const response = await api.get("/admin/videos/pending", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get videos for feedback failed");
    }
  } catch (error) {
    console.error("Get videos for feedback failed:", error);
    throw error;
  }
};

export const submitFeedback = async (
  videoId: string,
  percentage: number
): Promise<void> => {
  try {
    const response = await api.post("/admin/feedback", {
      videoId: videoId,
      percentage: percentage,
    });
    if (response.status !== 200) {
      throw new Error("Submit feedback failed");
    }
  } catch (error) {
    console.error("Submit feedback failed:", error);
    throw error;
  }
};

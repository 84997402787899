import React, { FC, useCallback, useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { getVideosForFeedback } from "../../api/admin";
import LearnSongsCard, { LearnSongsCardType } from "../songs/LearnSongsCard";
import { UserVideoForFeedback } from "../../models/UserVideoForFeedback";
import AdminUserVideoCard from "./AdminUserVideoCard";

interface AdminProps { }

const Admin: FC<AdminProps> = () => {
  const { authState } = useAuth();
  const { tokens } = authState;
  const isAdmin = authState.user?.isAdmin;
  const navigate = useNavigate();
  const [videosforFeedback, setVideosForFeedback] = useState<
    UserVideoForFeedback[]
  >(new Array<UserVideoForFeedback>());

  useEffect(() => {
    if (isAdmin === false) {
      navigate("/home");
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    let ignore = false;
    setVideosForFeedback([]);
    try {
      const accessToken = tokens?.access_token || "";
      getVideosForFeedback(accessToken).then((result) => {
        if (!ignore) {
          setVideosForFeedback(result);
        }
      });
      return () => {
        ignore = true;
      };
    } catch (error) {
      console.error("Error handling GET requests to /admin/videos/pending.");
    }
  }, []);

  const handleChildAction = useCallback(
    (userVideo: LearnSongsCardType) => {
      navigate("/admin-video-feedback", { state: { userVideo: userVideo } });
    },
    [navigate]
  );

  // const onClickLoginGoogle = useGoogleLogin({
  //   onSuccess: async (codeResponse) => {
  //     try {
  //       const refreshToken = await validateGoogleToken(codeResponse.code);
  //       // console.log("Google login success:", refreshToken);
  //     } catch (error) {
  //       console.error("Google login failed:", error);
  //     }
  //   },
  //   flow: "auth-code",
  //   scope: "https://www.googleapis.com/auth/youtube.upload",
  // });

  // const onClickParseAwsFiles = useCallback(async () => {
  //   try {
  //     const accessToken = tokens?.access_token || "";
  //     await parseAwsFiles(accessToken);
  //   } catch (error) {
  //     console.error("Parsing files:", error);
  //     throw error;
  //   }
  // }, []);

  return (
    <>
      <div className="bg-yellow py-5 border-bottom">
        <div className="container-gbc pt-5 text-black">
          <div className="row text-center pt-5">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <h2 className="fw-normal fs-gbc-1 mb-2">
                  Admin panel
                </h2>
                <p className="fs-gbc-2 fw-lighter mb-0 col-md-10 col-lg-6">
                  Here you will find the videos that you will need to rate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green">
        <div className="container-gbc py-4 py-md-5">
          <div className="row">
            {videosforFeedback.map((userVideo, index) => {
              return (
                <AdminUserVideoCard
                  key={userVideo.id}
                  video={userVideo}
                  action={() => handleChildAction(userVideo)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
